import './App.css';
import { useState } from 'react'
import React from 'react'
import { Container, Row, Form, Col, Button, Card } from 'react-bootstrap';
import { useAlert } from 'react-alert'

const url = 'https://worker.changnit.workers.dev/posts'
// const url = 'http://127.0.0.1:8787/posts'

async function getPosts() {
  const resp = await fetch(url, { method: 'GET' })
  const body = await resp.json()
  return body
}

async function submitPosts(title, content, username) {
  const timestamp = new Date().getTime()
  const req = { title, content, username, timestamp }
  console.log(req)
  const resp = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(req),
    headers: { 'content-type': 'application/json' }
  })
}

function App() {
  const alert = useAlert()

  const [user, setUser] = useState("")
  const [title, setTitle] = useState("")
  const [message, setMessage] = useState("")
  const [posts, setPosts] = useState([])

  const updateTitle = e => setTitle(e.target.value)
  const updateMessage = e => setMessage(e.target.value)
  const updateUser = e => setUser(e.target.value)

  /**
   * Check title, message, user is valid of not
   * @param title     message title
   * @param message   message content
   * @param user      user's name
   */
  const validate = (title, message, user) => {
    if (!title) {
      alert.show("Title should not be empty.")
      return false
    }
    if(!message) {
      alert.show("Message should not be empty.")
      return false
    }
    if (!user) {
      alert.show("User should not be empty.")
      return false
    }
    return true
  }

  const reset = () => {
    setTitle('')
    setMessage('')
    setUser('')
    document.getElementById("contentid").value = ""
    document.getElementById("titleid").value = ""
    document.getElementById("usernameid").value = ""
  }

  const submit = async (e) => {
    try {
      e.preventDefault()
      if (!validate(title, message, user)) {
        return
      }
      await submitPosts(title, message, user)
      const messages = await getPosts()
      console.log(messages)
      reset()
      alert.show("submit message success", { type: 'success' })
    } catch(e) {
      console.error(e)
    }
  }

  // call get post once
  let timer = null
  React.useEffect(() => {
    const f = async () => {
      try {
        const p = await getPosts()
        p.sort((a, b) => b.timestamp - a.timestamp)
        setPosts(p)
      } catch (e) {
        console.error(e)
      }
    }
    f()

    // polling
    timer = setInterval(f, 30000)
  }, [])

  return (
    <div className="App">
      <Container>
      <br/>
      <Row className="justify-content-center">
      <Col xs md lg="5">
      <h1>Welcome to my Kanban!</h1>
      <Form>
      <Form.Group className="mb-2" controlId="usernameid">
        <Form.Label>Username</Form.Label>
        <Form.Control type="text" onChange={updateUser} placeholder="Your Name..." />
      </Form.Group>
      <Form.Group className="mb-6" controlId="titleid">
        <Form.Label>Title</Form.Label>
        <Form.Control type="text" onChange={updateTitle} placeholder="Title..." />
      </Form.Group>
      <Form.Group className="mb-6" controlId="contentid">
        <Form.Label>Example textarea</Form.Label>
        <Form.Control as="textarea" rows={3} onChange={updateMessage} />
      </Form.Group>
      <br/>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button style={{ justifyContent: 'flex-end' }} variant="primary" onClick={submit}>Submit</Button>
      <Button style={{ justifyContent: 'flex-end' }} variant="light" onClick={reset}>Reset</Button>
      </div>
      </Form>
      </Col>
      </Row>
      <br/>
      <Row className="justify-content-center">
      <Col xs md lg="5">
      {posts.map((post, index) =>
        <Card key={index}>
        <Card.Body>
          <Card.Title className='alignright'>{post.title}</Card.Title>
          <Card.Subtitle className="alignright" className="mb-2 text-muted">{new Date(post.timestamp).toISOString()}</Card.Subtitle>
          <Card.Subtitle className="mb-2 text-muted">{post.username}</Card.Subtitle>
          <Card.Text>{post.content}</Card.Text>
        </Card.Body>
        </Card>
      )}
      </Col>
      </Row>
      </Container>
    </div>
  );
}

export default App;
